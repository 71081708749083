<template>
  <div>
    <wiki-add-new
      :is-add-new-wiki-sidebar-active.sync="isAddNewWikiSidebarActive"
      :title-options="titleOptions"
      :group-options="groupOptions"
    />

    <b-overlay
      variant="transparent"
      opacity="1.00"
      blur="2px"
      :show="showLoading === true"
      rounded="sm"
    >
      <b-card class="card-fo">
        <div class="m-2">
          <b-row>
            <!-- Search -->
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="mr-1">
                  <b-form-input
                    v-model="searchQuery"
                    @keyup.enter="searchWord()"
                    :placeholder="$t('message.bookmark.search')"
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="searchWord()"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <b-button
                  v-if="restrictions('button_new_wiki')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="isAddNewWikiSidebarActive = true"
                >
                  <span class="text-nowrap">{{
                    $t("message.buttons.newRelease")
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-card-body>
          <section id="faq-tabs">
            <b-tabs
              vertical
              content-class="col-12 col-md-8 col-lg-9"
              pills
              nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
              nav-class="nav-left"
            >
              <b-tab @click="showInfo = true" :active="showInfo === true">
                <template #title>
                  <span class="font-weight-bold">{{
                    $t("message.wiki.wiki")
                  }}</span>
                </template>
                <div accordion>

                  
                  <b-card :title="$t('message.wiki.welcomeWiki')" class="text-center">
                    <b-card-text>
                      {{ $t("message.wiki.instructionWiki") }} </b-card-text
                    ><br />
                    <b-img
                      fluid
                      :src="
                        require('@/assets/images/illustration/faq-illustrations.svg')
                      "
                      class="center"
                    />
                  </b-card>



                </div>
              </b-tab>

              <b-tab
                v-for="(categoryObj, categoryName, index) in titles"
                :key="categoryName"
                :active="index"
                @click="filterByTitle(categoryObj)"
              >
                <template #title>
                  <span class="font-weight-bold">{{ categoryObj }}</span>
                </template>

                <div class="d-flex align-items-center" v-if="!showInfo">
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                    class="mr-1"
                  >
                    <feather-icon icon="GlobeIcon" size="20" />
                  </b-avatar>
                  <div>
                    <h4 class="mb-0">
                      {{ categoryObj }}
                    </h4>
                  </div>
                </div>
              </b-tab>

              <app-collapse
                v-if="!showInfo"
                id="faq-payment-qna"
                accordion
                type="margin"
              >
                <app-collapse-item
                  v-for="(data, index) in this.body"
                  :key="index"
                  :title="data.subtitle"
                >
                  <div type="text" v-html="data.description"></div>

                  <div class="text-right">
                    <b-button
                      v-if="restrictions('button_delete_wiki')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon mr-1"
                      size="sm"
                      @click="selectCard(data)"
                      v-b-modal.modal-delete-confirmation
                      >X
                    </b-button>
                  </div>
                </app-collapse-item>
              </app-collapse>

              <b-modal
                id="modal-delete-confirmation"
                modal-class="modal-danger"
                centered
                :title="$t('delete')"
                ok-title="Deletar"
                ok-variant="danger"
                ok-only
                @ok="deleteWiki()"
              >
                <b-card-text>
                  {{ $t("message.deleteInformation") }} {{ this.dataQuestion }}
                  {{ $t("message.of") }} {{ $t("message.wiki.wiki") }} <b></b> ?
                </b-card-text>
              </b-modal>
            </b-tabs>
          </section>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BOverlay,
  BInputGroupAppend,
} from "bootstrap-vue";
import WikiAddNew from "./WikiAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    WikiAddNew,

    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BButton,
    AppCollapseItem,
    AppCollapse,
    BOverlay,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isAddNewWikiSidebarActive: false,
      titles: [],
      wikiData: [],
      body: [],
      titleOptions: [],
      dataQuestion: "",
      selectedId: "",
      showInfo: true,
      showLoading: true,

      searchQuery: "",

      //Select
      groupOptions: [],
      userData: store.state.user.userData,
    };
  },

  async created() {
    await this.loadData();

    var groupList = [];
    await axios
      .get(`${URL_API}userGroup`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const description = response.data[i].description;
          const accessControlId = response.data[i].accessControlId;
          groupList.push(description);
          this.groupOptions.push({
            label: description,
            value: accessControlId,
          });
        }
      });
  },

  mounted() {
    this.$root.$on("newListWiki", (newListWiki) => {
      this.wikiData = newListWiki;
      this.showInfo = true;

      var listOfTitles = [];
      for (var i = 0; i < this.wikiData.length; i++) {
        listOfTitles.push(this.wikiData[i].title);
      }

      this.titles = [];
      this.titles = listOfTitles.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);

      var list = [];
      this.titleOptions = [];
      for (let i = 0; i < this.titles.length; i++) {
        const element = this.titles[i];
        list.push(element);
        this.titleOptions.push({ label: list[i], value: list[i] });
      }
    });
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    clearData() {
      this.titleOptions = [];
      this.wikiData = [];
      this.body = [];
    },

    searchWord() {
      this.showInfo = true;
      if (this.searchQuery) {
        this.getWikiFilter();
      } else {
        this.loadData();
      }
    },

    async getWikiFilter() {
      this.showLoading = true;
      this.wikiData = [];
      await axios
        .get(`${URL_API}wiki/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.wikiData = response.data;
          this.showLoading = false;
          if (this.wikiData.length === 0) {
            this.clearData();
          }
        });

      var listOfTitles = [];
      for (var i = 0; i < this.wikiData.length; i++) {
        listOfTitles.push(this.wikiData[i].title);
      }

      this.titles = listOfTitles.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);

      var list = [];
      for (let i = 0; i < this.titles.length; i++) {
        const element = this.titles[i];
        list.push(element);
        this.titleOptions.push({ label: list[i], value: list[i] });
      }
    },

    async loadData() {
      this.showLoading = true;
      this.wikiData = [];
      await axios
        .get(`${URL_API}wiki`, { headers: getHeader(this.userData) })
        .then((response) => {
          this.wikiData = response.data;
          this.showLoading = false;
        });

      var listOfTitles = [];
      for (var i = 0; i < this.wikiData.length; i++) {
        listOfTitles.push(this.wikiData[i].title);
      }

      this.titles = listOfTitles.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);

      var list = [];
      for (let i = 0; i < this.titles.length; i++) {
        const element = this.titles[i];
        list.push(element);
        this.titleOptions.push({ label: list[i], value: list[i] });
      }
    },

    filterByTitle(title) {
      this.showInfo = false;
      this.body = [];
      for (var i = 0; i < this.wikiData.length; i++) {
        if (title === this.wikiData[i].title)
          this.body.push({
            subtitle: this.wikiData[i].subtitle,
            description: this.wikiData[i].description,
            id: this.wikiData[i].id,
          });
      }
    },

    selectCard(data) {
      this.dataQuestion = data.question;
      this.selectedId = data.id;
    },

    deleteWiki() {
      axios({
        method: "delete",
        url: `${URL_API}wiki/${this.selectedId}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A informação de ${this.dataQuestion} foi deletada com sucesso`,
            },
          });
        })
        .then(() => {
          this.loadData();
          this.showInfo = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";

.card-fo {
  background-color: rgba(245, 245, 245, 0.4);
}
.card-header,
.card-footer {
  opacity: 1;
}
</style>
